import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import Helmet from 'react-helmet'

type SeoPropTypes = {
  lang?: string
  imageUrl?: string
  title: string
  description?: string
  meta?: any
  url?: string
}

const Seo = (props: SeoPropTypes): JSX.Element => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            ogDescription
            ogImage
            author
            fbAppId
          }
        }
      }
    `
  )

  const {
    lang = useIntl().locale,
    title,
    description = site.siteMetadata.description,
    meta = [],
    imageUrl = site.siteMetadata.ogImage,
  } = props

  const ogDescription = props.description || site.siteMetadata.ogDescription

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        // TODO: Add SEO Keyword support
        // {
        //   name: 'keywords',
        //   content: tags,
        // },
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: `${title} | ${site.siteMetadata.title}`,
        },
        {
          property: 'og:description',
          content: ogDescription,
        },
        {
          property: 'fb:app_id',
          content: site.siteMetadata.fbAppId,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: imageUrl,
        },
        {
          property: 'og:url',
          content: props.url,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: `${title} | ${site.siteMetadata.title}`,
        },
        {
          name: 'twitter:description',
          content: ogDescription,
        },
      ].concat(meta)}
    />
  )
}

export default Seo
