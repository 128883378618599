import React from 'react'

const Animate: React.FC = ({ children }) => (
  <div
    data-sal="fade"
    data-sal-delay="300"
    data-sal-easing="ease"
    data-sal-duration="500"
  >
    {children}
  </div>
)

export default Animate
