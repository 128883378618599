import Icon from 'components/atoms/icon'
import Link from 'components/atoms/link'
import Collapse from 'components/molecules/collapse'
import NavigationList from 'components/molecules/navigation-list'
import RegionSwitcher from 'components/organisms/regionSwitcher'
import { getLocalNodes, regions } from 'helpers/locale'
import { useMainMenuNavigationData } from 'hooks/useMainMenuNavigationData'
import { useRegionalTextData } from 'hooks/useRegionalTextData'
import { useResourceTextData } from 'hooks/useResourceTextData'
import Logo from 'images/logo.svg'
import Cart from 'images/svg/cart.svg'
import ChevDown from 'images/svg/chev-down.svg'
import Close from 'images/svg/close.svg'
import Menu from 'images/svg/menu.svg'
import jsonp from 'jsonp'
import React, { useEffect, useState } from 'react'
import { Waypoint } from 'react-waypoint'
import styled, { css, CSSProp } from 'styled-components'
const StyledHeader = styled.header<{
  type?: string
  top: boolean
  menuOpened: boolean
}>`
  ${({ theme, type, top, menuOpened }): CSSProp => css`
    height: ${type === 'trans' ? 'auto' : theme.layout.headerHeightMobile};
    text-transform: uppercase;
    font-size: ${theme.font.fontSizeSmall};
    letter-spacing: ${theme.font.fontLetterSpacingNav};
    position: relative;
    z-index: 101;
    @media only screen and ${theme.breakpoints.fromLargeScreen} {
      height: ${type === 'trans' ? 'auto' : theme.layout.headerHeightDesktop};
    }
    .inner {
      display: flex;
      align-items: center;
      padding: 0 2rem;
      position: fixed;
      width: 100%;
      height: ${theme.layout.headerHeightTablet};
      z-index: 100;
      transition: ${theme.animation.defaultLength} ease;
      color: ${
        type === 'trans' && top && !menuOpened
          ? theme.colours.secondary
          : theme.colours.primary
      };
      background-color: ${
        type === 'trans' && top ? 'transparent' : theme.colours.secondary
      };
      
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        height: ${theme.layout.headerHeightDesktop};
      }
      @media only screen and ${theme.breakpoints.toMediumScreen} {
        ${type === 'light' &&
          !menuOpened &&
          css`
            background-color: ${theme.colours.lightTint3};
          `}
      }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    nav > ul {
      > li {
        display: inline;
        margin: 0 2.2rem;
        padding: 1rem 0;
        position: relative;
      }
      ul {
        display: none;
        min-width: 20rem;
        padding: 1rem 2.5rem;
        position: absolute;
        top: 100%;
        left: 0;
        background: ${theme.colours.secondary};
        opacity: .9;
        box-shadow: 0 8px 10px 0 rgba(43, 25, 22, 0.12);
        text-align: left;
        color: black;
        border-radius: 2px;
        li {
          margin: 1rem 0;
        }
        a:hover {
          text-decoration: underline;
        }
      }
      li:hover ul {
        display: block;
      }
      svg {
        margin-left: .8rem;
        vertical-align: middle;
        height: .6rem;
      }
    }

    a {
      text-decoration: none;
    }
    .button-wrap {
      flex: 1;
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        display: none;
      }
    }
    .logo {
      @media only screen and ${theme.breakpoints.fromXLargeScreen} {
        flex: 1;
      }
      svg {
        display: block;
        height: 3rem;
        @media only screen and ${theme.breakpoints.toNormalScreen} {
          height: 2.6rem;
        }
      }
    }
    .primary {
      white-space: nowrap;
      text-align: center;
      @media only screen and ${theme.breakpoints.toXLargeScreen} {
         flex: 1;
      }
      @media only screen and ${theme.breakpoints.toLargeScreen} {
        display: none;
      }
    }
    .secondary {
      white-space: nowrap;
      text-align: right;
      @media only screen and ${theme.breakpoints.fromXLargeScreen} {
        flex: 1;
      }
      @media only screen and ${theme.breakpoints.toLargeScreen} {
        flex: 1;
      }
      .cart-link {
        margin: 0;
        svg {
          @media only screen and ${theme.breakpoints.toNormalScreen} {
            height: 1.8rem;
          }
          @media only screen and ${theme.breakpoints.fromNormalScreen} {
            height: 2.4rem;
          }
          @media only screen and ${theme.breakpoints.fromLargeScreen} {
            height: 1.6rem;
          }
        }
        span {
          margin-left: .5rem;
        }
      }
      li:not(.cart-link) {
        @media only screen and ${theme.breakpoints.toLargeScreen} {
          display: none;
        }
      }
    }
  `}
`

const MobileMenu = styled.nav<{ opened: boolean }>`
  ${({ theme, ...props }): CSSProp => css`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: ${theme.colours.secondary};
    padding: ${theme.layout.headerHeightTablet} 2rem 3rem;
    transition: all ${theme.animation.defaultLength} ease;
    opacity: 0;
    transform: translateY(-100%);
    ${props.opened &&
      css`
        transform: translateY(0);
        opacity: 1;
      `}
    @media only screen and ${theme.breakpoints.fromLargeScreen} {
      display: none;
    }
    
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      ul {
        background: ${theme.colours.lightTint3};
        padding: 0 2rem;
        li {
          border: none;
          &:first-child {
            padding-top: 1rem;
          }
          &:last-child {
            padding-bottom: 1rem;
          }
        }
        a {
          padding: .4rem 0;
          font-weight: normal;
          text-transform: none;
          font-weight: ${theme.font.fontWeightLight};
          font-size: 1.7rem;
        }
      }
      li {
        position: relative;
      }
      a {
        font-weight: bold;
        text-transform: uppercase;
        font-size: ${theme.font.fontSizeMid};
        letter-spacing: 1px;
      }
      .collapse-body {
        margin-left: -2rem;
        margin-right: -2rem;
      }
    }
    li {
      border-bottom: solid ${theme.colours.tertiary} 1px;
    }
    a {
      display: block;
      text-decoration: none;
      padding: 2.5rem 0 1rem;
    }
    .toggle {
      width: 4rem;
      height: 3rem;
      position: absolute;
      top: 1.5em;
      right: -1rem;
      
    }
    .contact-details {
      padding: 1.5rem 0;
      line-height: 2em;
      a {
        display: inline;
        text-transform: none;
        font-weight: normal;
        font-size: 1.6rem;
        letter-spacing: 0;
        padding: 0;
      }
    }
  `}
`

const MenuButton = styled.button`
  ${({ theme }): CSSProp => css`
    cursor: pointer;
    border: none;
    background: none;
    color: currentColor;
    outline: none;
    padding: 0;
    margin-top: 0.4rem;
    div {
      @media only screen and ${theme.breakpoints.toNormalScreen} {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  `}
`

const Header = ({ type }: { type?: string }): JSX.Element => {
  const [menuOpened, setMenuOpened] = useState(false)
  const [top, setTop] = useState(true)
  const [cartItemsCount, setCartItemsCount] = useState(0)

  const navigation = useMainMenuNavigationData()
  const navigationLocal = getLocalNodes(navigation.nodes)
  const { navigationItems } = navigationLocal[0]

  useEffect(() => {
    if (window.scrollY > 0) {
      setTop(false)
    }

    // get shopify cart
    const getShopifyCart = async (): Promise<any> => {
      jsonp(
        `${regions[process.env.GATSBY_BUILD_LOCALE].shop}cart.json`,
        null,
        function(err, data) {
          if (err) {
            // silent fail
          } else {
            setCartItemsCount(data.item_count)
          }
        }
      )
    }
    getShopifyCart()
  }, [])

  return (
    <>
      <StyledHeader type={type} top={top} menuOpened={menuOpened}>
        <Waypoint
          onEnter={(): void => setTop(true)}
          onLeave={(): void => setTop(false)}
        />

        <div className="inner">
          <div className="button-wrap">
            <MenuButton
              type="button"
              onClick={(): void => setMenuOpened(!menuOpened)}
            >
              <Icon>{menuOpened ? <Close /> : <Menu />}</Icon>
            </MenuButton>
          </div>
          <div className="logo">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <nav className="primary">
            <NavigationList navigationItems={navigationItems} />
          </nav>
          <nav className="secondary">
            <ul>
              <li>
                {regions[process.env.GATSBY_BUILD_LOCALE].name}
                <ChevDown />
                <RegionSwitcher />
              </li>
              <li>
                <a
                  href={`${
                    regions[process.env.GATSBY_BUILD_LOCALE].shop
                  }account`}
                >
                  {useResourceTextData('header.accountText', 'Account')}
                </a>
              </li>
              <li className="cart-link">
                <a
                  href={`${regions[process.env.GATSBY_BUILD_LOCALE].shop}cart`}
                >
                  <Cart />{' '}
                  {cartItemsCount > 0 && <span>({cartItemsCount})</span>}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </StyledHeader>
      <MobileMenu opened={menuOpened}>
        <nav className="primary">
          <NavigationList navigationItems={navigationItems} expandable />
        </nav>
        <nav className="secondary">
          <ul>
            <li className="account-link">
              <a
                href={`${regions[process.env.GATSBY_BUILD_LOCALE].shop}account`}
              >
                {useResourceTextData('header.accountText', 'Account')}
              </a>
            </li>
            <li className="contact-details">
              {useRegionalTextData('global.email')}
              <br />
              {useRegionalTextData('global.phone')}
            </li>
            <li className="region-links">
              <a href="#">
                {useResourceTextData('footer.currentRegionText', "You're in")}{' '}
                {regions[process.env.GATSBY_BUILD_LOCALE].name}
              </a>

              <Collapse button={<ChevDown />}>
                <RegionSwitcher />
              </Collapse>
            </li>
          </ul>
        </nav>
      </MobileMenu>
    </>
  )
}

Header.propTypes = {}

export default Header
