import Icon from 'components/atoms/icon'
import Container from 'components/container'
import NavigationLink from 'components/molecules/navigation-link'
import NavigationList, { LinkList } from 'components/molecules/navigation-list'
import RegionSwitcher from 'components/organisms/regionSwitcher'
import { getLocalNodes } from 'helpers/locale'
import { regions } from 'helpers/locale'
import { useCopyrightNavigationData } from 'hooks/useCopyrightNavigationData'
import { useFooterNavigationData } from 'hooks/useFooterNavigationData'
import { useRegionSwitcher } from 'hooks/useRegionSwitcher'
import { useResourceTextData } from 'hooks/useResourceTextData'
import Ig from 'images/svg/ig.svg'
import Yt from 'images/svg/youtube.svg'
import React, { useEffect, useRef, useState } from 'react'
import styled, { css, CSSProp } from 'styled-components'

const StyledFooter = styled.footer`
  ${({ theme }): CSSProp => css`
    padding: 6rem 0 2rem;
    background: ${theme.colours.primary};
    color: ${theme.colours.secondary};
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      padding: 8rem 0 2rem;
    }
  `}
`

const FooterMain = styled.div`
  ${({ theme }): CSSProp => css`
    @media only screen and ${theme.breakpoints.toNormalScreen} {
      display: flex;
      flex-wrap: wrap;
    }
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 1rem;
    }
    @media only screen and ${theme.breakpoints.fromLargeScreen} {
      grid-template-columns: 1fr 1fr 1fr 2fr;
    }

    > div {
      flex: 1;
      margin-bottom: 20px;
      @media only screen and ${theme.breakpoints.toNormalScreen} {
        flex-basis: 50%;
        :last-child {
          flex-basis: 100%;
          order: -1;
        }
      }
      /* email link */

      &:nth-child(3) li:first-child a {
        text-decoration: underline;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      margin: 1.2rem 0;
    }

    h4 {
      color: ${theme.colours.tertiary};
      text-transform: uppercase;
      font-size: ${theme.font.fontSizeSmall};
      letter-spacing: 1.5px;
      font-weight: normal;
      margin-bottom: 2rem;
    }

    a {
      text-decoration: none;
      font-size: ${theme.font.fontSizeLarge};
      @media only screen and ${theme.breakpoints.toNormalScreen} {
        font-size: ${theme.font.fontSizeDesktop};
      }
    }
  `}
`
const Copyright = styled.div`
  ${({ theme }): CSSProp => css`
    font-size: 1.3rem;
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      display: flex;
      justify-content: space-between;
      margin-top: 100px;
    }
    .links {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        @media only screen and ${theme.breakpoints.toNormalScreen} {
          margin-top: 20px;
          display: flex;
          flex-wrap: wrap;
        }
      }

      li {
        display: inline;
        font-size: ${theme.font.fontSizeSmall};
        @media only screen and ${theme.breakpoints.toNormalScreen} {
          margin-right: 1rem;
          :last-child {
            order: -1;
            flex-basis: 100%;
          }
        }
        @media only screen and ${theme.breakpoints.fromNormalScreen} {
          margin-left: 3rem;
        }
      }
    }

    button {
      cursor: pointer;
      margin-left: 0.8rem;
      background: none;
      border: none;
      color: ${theme.colours.tertiary};
      text-decoration: underline;
    }

    .region-selector {
      position: relative;
      ul {
        position: absolute;
        right: 0;
        bottom: 100%;
        width: 20rem;
        background: ${theme.colours.secondary};
        padding: 1rem 1.5rem;
        margin-bottom: 1rem;
      }
      li {
        display: block;
        margin: 0.6rem 0;
        @media only screen and ${theme.breakpoints.fromNormalScreen} {
          margin: 0;
        }
      }
      a {
        color: ${theme.colours.primary};
        text-decoration: none;
        font-size: 1.6rem;
      }
    }
  `}
`

const NewsletterForm = styled.div`
  font-size: 1.3rem;
  margin-bottom: 1.5rem;

  .copy {
    max-width: 30rem;
  }
`

const Social = styled.div`
  ${({ theme }): CSSProp => css`
    a {
      color: ${theme.colours.tertiary};
      margin-right: 0.7rem;
      display: inline-block;
    }
  `}
`

const Footer = (): JSX.Element => {
  const navigation = useFooterNavigationData()
  const navigationLocal = getLocalNodes(navigation.nodes)
  const { navigationItems } = navigationLocal[0]

  const copyrightNavigation = useCopyrightNavigationData()
  const copyrightNavigationLocal = getLocalNodes(copyrightNavigation.nodes)
  const {
    navigationItems: copyrightNavigationItems,
  } = copyrightNavigationLocal[0]

  const [regionSelectorActive, setRegionSelectorActive] = useState(false)

  // handle click outside events
  const menuRef = useRef(null)
  const onClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setRegionSelectorActive(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside)
    return (): void => {
      document.removeEventListener('mousedown', onClickOutside)
    }
  }, [])

  return (
    <StyledFooter>
      <Container>
        <FooterMain>
          {navigationItems.map((item, i) => (
            <div key={i}>
              <h4>
                <NavigationLink menuItem={item} />
              </h4>
              {item.menuItems && <LinkList items={item.menuItems} />}
              {i === navigationItems.length - 1 && (
                <>
                  <NewsletterForm>
                    <div className="copy">
                      {useResourceTextData(
                        'footer.signUpText',
                        'Stay up to date'
                      )}
                    </div>
                    <div className="klaviyo-form-S6JiLs" />
                  </NewsletterForm>
                  <Social>
                    <a
                      href={useResourceTextData('footer.instagramLink')}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Icon>
                        <Ig />
                      </Icon>
                    </a>{' '}
                    <a
                      href={useResourceTextData('footer.youtubeLink')}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Icon>
                        <Yt />
                      </Icon>
                    </a>
                  </Social>
                </>
              )}
            </div>
          ))}
        </FooterMain>
        <Copyright>
          <div>
            {useResourceTextData('footer.currentRegionText', "You're in")}{' '}
            {regions[process.env.GATSBY_BUILD_LOCALE].name}{' '}
            <span className="region-selector">
              <button
                type="button"
                onClick={(): void =>
                  setRegionSelectorActive(!regionSelectorActive)
                }
              >
                {useResourceTextData(
                  'footer.changeRegionText',
                  'Change region'
                )}
              </button>
              {regionSelectorActive && (
                <div ref={menuRef}>
                  <RegionSwitcher />
                </div>
              )}
            </span>
          </div>
          <div className="links">
            <NavigationList navigationItems={copyrightNavigationItems} />
          </div>
        </Copyright>
      </Container>
    </StyledFooter>
  )
}

export default Footer
