import 'slick-carousel/slick/slick.css'

import { createGlobalStyle, css, CSSProp } from 'styled-components'

const Styles = createGlobalStyle`
  ${({ theme }): CSSProp => css`
    @font-face {
      font-family: 'AdelleSans';
      font-style: normal;
      font-weight: 300;
      font-display: swap;
      src: url('/fonts/AdelleSans-Light.woff2') format('woff2'),
        url('/fonts/AdelleSans-Light.woff') format('woff');
    }

    @font-face {
      font-family: 'AdelleSans';
      font-style: normal;
      font-weight: normal;
      font-display: swap;
      src: url('/fonts/AdelleSans-Regular.woff2') format('woff2'),
        url('/fonts/AdelleSans-Regular.woff') format('woff');
    }

    /* @font-face {
      font-family:  'AdelleSans';
      font-style:  normal;
      font-weight: 500;
      font-display: swap;
      src: url("/fonts/AdelleSans-Semobold.woff2") format("woff2"),
          url("/fonts/AdelleSans-Semobold.woff") format("woff");
    } */

    @font-face {
      font-family: 'Copernicus';
      font-style: normal;
      font-weight: bold;
      font-display: swap;
      src: url('/fonts/Copernicus-Bold.woff2') format('woff2'),
        url('/fonts/Copernicus-Bold.woff') format('woff');
    }

    @font-face {
      font-family: 'Copernicus';
      font-style: normal;
      font-weight: 500;
      font-display: swap;
      src: url('/fonts/Copernicus-Medium.woff2') format('woff2'),
        url('/fonts/Copernicus-Medium.woff') format('woff');
    }

    * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    *,
    *::before,
    *::after {
      box-sizing: border-box;
      -webkit-overflow-scrolling: touch;
    }

    html {
      /* To use 1 : 10 ratio between pixels and rems. 1rem = 10px */
      font-size: 62.5%;
    }

    body {
      font-family: 'AdelleSans', Tahoma, Arial, Helvetica, sans-serif;
      font-size: 1.6rem;
      line-height: 1.65;
      color: ${theme.colours.primary};
      margin: 0;
      background-color: ${theme.colours.secondary};
    }

    img {
      display: block;
      width: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.22;
      margin-top: 0;
    }

    hr {
      border: 1px solid #e6e6eb;
      margin: 4rem 0;
    }

    p {
      min-height: 1em;
    }

    p strong {
      font-weight: 600;
    }

    a {
      color: currentColor;
    }

    button {
      border: none;
    }

    a,
    button {
      transition: color ${theme.animation.defaultLength} ease,
        background ${theme.animation.defaultLength} ease;
    }

    .wrapper {
      width: calc(100% - 10vmin);
      margin: 0 auto;
      padding: 5vmin 0;
    }

    .gatsby-image-wrapper img {
      transition: all ${theme.animation.defaultLength} ease !important;
    }
    a:hover .gatsby-image-wrapper img {
      transform: scale(1.1);
    }

    /**
    * article grid
    */
    .article-list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 5vmin;
    }

    /**
    *
    */
    .section-headline {
      padding: 0 0 0.4em 0;
      margin: 0 0 5vmin 0;
      border-bottom: 1px solid #ddd;
    }

    /**
    *
    */
    .list-inline {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .list-inline li {
      display: inline-block;
    }
  `}
`

export default Styles
