import Autolinker from 'autolinker'
import { useIntl } from 'gatsby-plugin-intl'
import { convertLineBreaks } from 'helpers/text'
import parse from 'html-react-parser'

import {
  ContentfulCtaBlockBodyTextNode,
  ContentfulLinkContentfulRegionalLinkUnion,
  ContentfulNavigationItem,
  Maybe,
} from '../types/graphql-types'
import { defaultRegion, languages } from './languages'
export { regions } from './regions'

export function getCurrentLang(): string {
  const intl = useIntl()
  return languages[intl.locale]
}

type NodesType = {
  key: string
  node_locale: string
  body?: Maybe<ContentfulCtaBlockBodyTextNode>
  cta?: Maybe<Array<Maybe<ContentfulLinkContentfulRegionalLinkUnion>>>
  navigationItems?: Maybe<Array<Maybe<ContentfulNavigationItem>>>
}[]

export function getLocalNodes(nodes: any): any {
  const intl = useIntl()
  const locale = languages[intl.locale]
  return nodes.filter(n => n.node_locale === locale)
}

export const getRegionalLink = (regionalLink): string => {
  return (
    regionalLink[`${process.env.GATSBY_BUILD_LOCALE || defaultRegion}Slug`]?.[
    getCurrentLang()
    ] || regionalLink[`${process.env.GATSBY_BUILD_LOCALE || defaultRegion}Slug`]
  )
}

export const getRegionalText = (regionalText): JSX.Element | JSX.Element[] => {

  return parse(
    convertLineBreaks(
      Autolinker.link(
        regionalText[`${process.env.GATSBY_BUILD_LOCALE || defaultRegion}Text`][`${process.env.GATSBY_BUILD_LOCALE || defaultRegion}Text`]
      )
    )

  )

}
