import React from 'react'
import { ThemeProvider } from 'styled-components'

import theme from './atoms/theme'
import ErrorBoundary from './molecules/error-boundary'
import Footer from './organisms/footer'
import Header from './organisms/header'
import Styles from './Styles'

type LayoutPropTypes = {
  children: React.ReactNode
  header?: string
}

const Layout = ({ children, header }: LayoutPropTypes): JSX.Element => (
  <ThemeProvider theme={theme}>
    <Styles />
    <Header type={header} />
    <ErrorBoundary>{children}</ErrorBoundary>
    <Footer />
  </ThemeProvider>
)

export default Layout
