import Button from 'components/atoms/button'
import Icon from 'components/atoms/icon'
import Link from 'components/atoms/link'
import { getRegionalLink, getRegionalText } from 'helpers/locale'
import Arrow from 'images/svg/arrow-right.svg'
import React from 'react'
import { ContentfulLink } from 'types/graphql-types'

const getLink = (menuItem): string => {
  if (menuItem?.internal?.type) {
    switch (menuItem.internal.type) {
      case 'ContentfulRegionalLink':
        return getRegionalLink(menuItem)

      case 'ContentfulLink':
        return menuItem.link

      case 'ContentfulArticle':
        return `/articles/${menuItem.slug}`

      case 'ContentfulPage':
        return `/${menuItem.slug}`

      default:
        console.error(`getLink: ${menuItem.internal.type} Type not handled`)
    }
  }
  return ''
}

const NavigationLink = ({
  menuItem,
  type,
  alternate,
}: {
  menuItem: ContentfulLink | any
  type?: string
  alternate?: boolean
}): JSX.Element => {
  const menuItemType = menuItem.internal.type

  let url = menuItem.url
  const title = menuItem.title

  switch (menuItemType) {
    case 'ContentfulNavigationItem':
      url = menuItem.link ? getLink(menuItem.link) : menuItem.url
      break

    case 'ContentfulRegionalLink':
      url = getLink(menuItem)
      break

    case 'ContentfulLink':
      url = menuItem.link
      break

    case 'ContentfulRegionalText':
      return <>{getRegionalText(menuItem)}</>

    default:
      console.error(`NavigationLink: ${menuItemType} Type not handled`)
  }

  const Component = type === 'button' ? Button : Link
  return (
    <Component alternate={alternate} to={url}>
      {type === 'iconLink' && (
        <Icon className="icon" size="small">
          <Arrow />
        </Icon>
      )}
      {title}
    </Component>
  )
}

export default NavigationLink
