const regions = {
  nz: {
    name: 'New Zealand',
    slug: 'new-zealand',
    shop: '//nz.shop.allpressespresso.com/',
    location: {
      bounds: [
        [-34.76671725, 166.2361908],
        [-47.30251579, 177.66849518],
      ],
    },
  },
  au: {
    name: 'Australia',
    slug: 'australia',
    shop: '//au.shop.allpressespresso.com/',
    location: {
      bounds: [
        [-9.884787281776832, 111.03002880347088],
        [-46.22537773392607, 153.92244574887337],
      ],
    },
  },
  uk: {
    name: 'United Kingdom',
    slug: 'united-kingdom',
    shop: '//uk.shop.allpressespresso.com/',
    location: {
      bounds: [
        [59.930835387909006, -12.192248502392891],
        [49.420109384682085, 3.0555400400789443],
      ],
    },
  },
  sg: {
    name: 'Singapore',
    slug: 'singapore',
    shop: '//sg.shop.allpressespresso.com/',
    location: {
      bounds: [
        [1.4936062850329703, 103.56574710892916],
        [1.2015342619598457, 104.12906540598546],
      ],
    },
  },
  jp: {
    name: 'Japan',
    nameJp: '日本',
    slug: 'japan',
    shop: '//jp.shop.allpressespresso.com/',
    location: {
      bounds: [
        [45.69222005300799, 128.1329956408116],
        [29.803784817868266, 145.62841829459666],
      ],
    },
    languages: [
      {
        name: 'English',
        prefix: '/',
      },
      {
        name: '日本語',
        prefix: '/ja',
      },
    ],
  },
}

exports.regions = regions
