import { regions } from 'helpers/locale'
import React from 'react'
import { useCookies } from 'react-cookie'

const RegionSwitcher = (): JSX.Element => {
  const [cookies, setCookie] = useCookies(['allpress'])

  const regionSwitch = (region: string): void => {
    setCookie('origin', region, { path: '/' })
  }

  return (
    <ul>
      {Object.keys(regions).map(id => {
        if (regions[id].languages) {
          return regions[id].languages.map(({ name, prefix }) => (
            <li key={`${id}${name}`}>
              <a
                href={`${prefix}`}
                onClick={(): void => {
                  regionSwitch(id)
                }}
              >
                {regions[id].name} - {name}
              </a>
            </li>
          ))
        } else {
          return (
            <li key={id}>
              <a
                href="/"
                onClick={(): void => {
                  regionSwitch(id)
                }}
              >
                {regions[id].name}
              </a>
            </li>
          )
        }
      })}
    </ul>
  )
}

export default RegionSwitcher
